/* eslint-disable no-dupe-keys */
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
const tabHeight = '32px';

export const AntSwitch = withStyles((theme) => ({
  root: {
    width: 30,
    height: 16,
    padding: 0,
    marginRight: '.3em'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

export const useSignupStyles = makeStyles({
  root: {
    '& .MuiSvgIcon-root': {
      // fontSize: '13px'
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      background: '#E0EEF0',
      borderRadius: '50px',
      minHeight: '20px',
      color: '#2898A4',
      fontWeight: 600,
      fontSize: '14px',
      fontStyle: 'normal',
      overflow: 'hidden'
    },
    '& .MuiTabs-indicator': {
      background: 'transparent !important'
    },
    '& .MuiTab-wrapper': {
      textTransform: 'capitalize'
    },
    '& .MuiTab-root': {
      // border: '1px solid purple',
      // width: '240px',
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-input': {
      background: '#FAFAFA'
    },
    '& .MuiTypography-h5': {
      fontWeight: 700,
      color: '#545454',
      fontSize: '18px',
      fontStyle: 'normal',
      paddingBottom: '.5em'
    },
    '& .MuiTypography-h6': {
      fontWeight: 800,
      color: '#545454',
      fontSize: '30px',
      fontStyle: 'normal',
      paddingBottom: '.5em'
    },
    // '& .MuiTypography-subtitle2': {
    //   paddingBottom: '.7em'
    // },
    '& .MuiTab-textColorInherit.Mui-selected': {
      background: '#E0EEF0',
      borderRadius: '50px',
      minHeight: '20px',
      color: '#2898A4',
      fontWeight: 600,
      fontSize: '14px',
      fontStyle: 'normal'
    },
    '& .MuiTabs-indicator': {
      background: 'transparent !important'
    },
    '& .MuiTab-wrapper': {
      textTransform: 'capitalize'
    },
    '& .MuiTypography-body2': {
      padding: '0px 0px .5em',
      color: '#545454',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .test': {
      border: '1px solid #E1E1E1',
      borderRadius: '21px',
      padding: '2em',
      '& .MuiTypography-h6': {
        color: '#545454',
        fontWeight: 700,
        fontSize: '18px',
        marginBottom: '.5em'
      },
      '& .summaryWrapper': {
        padding: '1em',
        borderRadius: '11px',
        border: '1px solid #2898A4',
        background: '#E0EEF0',
        boxShadow: '0px 5px 18px rgba(40, 41, 61, 0.07)'
      },
      '& .employeeNumber': {
        margin: '.6em 0px',
        '& .MuiTypography-subtitle2': {
          color: '#545454',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          paddingBottom: '0px'
        }
      },
      '& .tier': {
        '& .MuiTypography-subtitle1': {
          color: '#545454',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      '& .MuiTab-textColorInherit.Mui-selected': {
        background: '#E0EEF0',
        borderRadius: '50px',
        minHeight: '20px',
        color: '#2898A4',
        fontWeight: 600,
        fontSize: '14px',
        fontStyle: 'normal'
      },
      '& .MuiTabs-indicator': {
        background: 'transparent !important'
      },
      '& .MuiTab-wrapper': {
        textTransform: 'capitalize'
      },
      '& .trial': {
        fontSize: '14px',
        textAlign: 'center',
        padding: '1em',
        fontWeight: 600,
        color: '#545454',
        textDecoration: 'underline'
      }
    }
  },
  inputWrapper: {
    marginBottom: '1em'
  },
  divider: {
    margin: '2em 0'
  },
  plan: {
    border: '1.02058px solid #E1E1E1',
    borderRadius: '50px',
    width: '100%',
    margin: '0px auto',
    overflow: 'hidden',
    background: '#FAFAFA',
    '& .MuiTabs-flexContainer': {
      display: 'block'
    }
  },
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight
  },
  user: {
    '& .MuiOutlinedInput-input': {
      textAlign: 'center'
    }
  },
  summary: {
    border: '1px solid #E1E1E1',
    background: '#FAFAFA',
    padding: '10.5px 10px 10.5px 20px',
    borderRadius: '5px',
    margin: '2em auto',
    width: '100%',

    '& .MuiTypography-subtitle2': {
      color: '#878787',
      fontWeight: 400,
      fontSize: '14px'
    },
    '& .MuiTypography-subtitle1': {
      color: '#545454',
      fontWeight: 400,
      fontSize: '17px'
    }
  },
  recurring: {
    '& .MuiTypography-subtitle2': {
      color: '#878787',
      fontWeight: 400,
      fontSize: '14px'
    }
  },
  loaderWrapper: {
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0) 56.25%, #D6F2F5 100%)',
    height: '100vh',
    width: '100%',
    '& .MuiTypography-subtitle1': {
      color: '#333333',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '28px',
      lineHeight: '138.55%'
    },
    '& .MuiTypography-subtitle2': {
      color: '#878787',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '18px',
      lineHeight: '175.55%',
      textAlign: 'center',
      maxWidth: '80%',
      margin: 'auto'
    }
  }
});
