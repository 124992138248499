let user = JSON.parse(localStorage.getItem('user_information'));
const moduleAccess = user?.permissions?.map((el) => el.codename) || [];

const routes = [
  '/',
  '/404',
  '/login',
  '/terms',
  '/signup',
  '/premium',
  '/account',
  '/dashboard',
  '/verify-invite',
  '/password-reset',
  '/privacy-policy',
  '/forgot-password',
  '/public-reference',
  '/unauthorized-access',
  '/forgot-password-confirm',
  moduleAccess.includes('access_hr') &&
    [
      '/hr',
      '/add-employee-new',
      '/hr/report/turnover',
      '/hr/report/employee-count',
      '/hr/report/head-count',
      '/hr/report/diversity-demographic',
      '/hr/report/age-profile',
      '/hr/report/salaries',
      '/settings/location'
    ].map((item) => {
      return item;
    }),
  moduleAccess.includes('access_payroll') &&
    ['/payroll'].map((item) => {
      return item;
    }),
  moduleAccess.includes('access_attendance') &&
    ['/time-and-attendance'].map((item) => {
      return item;
    }),
  moduleAccess.includes('access_assets') &&
    ['/assets'].map((item) => {
      return item;
    }),
  moduleAccess.includes('access_vehicles') &&
    ['/fleet'].map((item) => {
      return item;
    }),
  moduleAccess.includes('access_accounting') &&
    ['/accounting'].map((item) => {
      return item;
    }),
  moduleAccess.includes('access_recruits') &&
    ['/recruit'].map((item) => {
      return item;
    }),
  moduleAccess.includes('access_shift') &&
    ['/shift-rota'].map((item) => {
      return item;
    }),
  moduleAccess.includes('access_performance') &&
    ['/performance'].map((item) => {
      return item;
    }),
  moduleAccess.includes('access_myteam') &&
    ['/myteam'].map((item) => {
      return item;
    })
];

const excludedRoutes = routes.flat();

const getModuleRoutes = () => {
  let user = JSON.parse(localStorage.getItem('user_information'));
  const moduleAccess = user?.permissions?.map((el) => el.codename) || [];
  const routes = [
    '/',
    '/404',
    '/login',
    '/terms',
    '/signup',
    '/premium',
    '/account',
    '/dashboard',
    '/verify-invite',
    '/password-reset',
    '/privacy-policy',
    '/forgot-password',
    '/public-reference',
    '/unauthorized-access',
    '/forgot-password-confirm',
    moduleAccess.includes('access_hr') && [
      '/hr',
      '/add-employee-new',
      '/hr/report/turnover',
      '/hr/report/employee-count',
      '/hr/report/head-count',
      '/hr/report/diversity-demographic',
      '/hr/report/age-profile',
      '/hr/report/salaries',
      '/settings/location'
    ],
    moduleAccess.includes('access_payroll') && ['/payroll'],
    moduleAccess.includes('access_attendance') && ['/time-and-attendance'],
    moduleAccess.includes('access_assets') && ['/assets'],
    moduleAccess.includes('access_vehicles') && ['/fleet'],
    moduleAccess.includes('access_accounting') && ['/accounting'],
    moduleAccess.includes('access_recruits') && ['/recruit'],
    moduleAccess.includes('access_shift') && ['/shift-rota'],
    moduleAccess.includes('access_performance') && ['/performance'],
    moduleAccess.includes('access_myteam') && ['/myteam']
  ];

  return routes.flat().filter(Boolean);
};

export { excludedRoutes, getModuleRoutes };
