// import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import { createContext, useContext } from 'react';
import { getToken } from 'utilities/utils';
import { getUser } from 'api/common';

const AuthContext = createContext();
AuthContext.displayName = 'AuthContext';

const useStyles = makeStyles((theme) => ({
  loader: {
    width: '20%',
    margin: '10em auto',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '2em auto'
    }
  }
}));

export default function AuthProvider(props) {
  const classes = useStyles();
  const access_token = getToken();
  // using useQuery the object structure
  const result = useQuery({
    queryKey: 'user',
    queryFn: getUser,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    enabled: Boolean(access_token)
  });

  if (Boolean(access_token) && result.isLoading) {
    return (
      <div className={classes.loader}>
        <img
          src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638526892/Bizedge_Frontend_Assets/BizLoader_dzmb4i.gif'
          width={250}
          height={250}
          alt='loading spinner'
        />
      </div>
    );
  }
  return <AuthContext.Provider value={result} {...props} />;
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
}
