import { useLocation } from 'react-router-dom';

export const useUTMParams = () => {
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const utmSource = queryParams.get('utm_source') || null;
  const utmMedium = queryParams.get('utm_medium') || null;
  const utmCampaign = queryParams.get('utm_campaign') || null;

  if (utmSource || utmMedium || utmCampaign) {
    return { utmSource, utmMedium, utmCampaign };
  }

  return null;
};
