import { Modal } from 'antd';
import Typography from 'components/Typography';
import React from 'react';
import { usePlanRestrictionContext } from './PlanRestrictionContext';
import { CustomButton } from 'components/Button';
import { Stack } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const PlanRestrictionModal = () => {
  const [opened, { toggle }] = usePlanRestrictionContext();
  const history = useHistory();
  const close = () => {
    // history.goBack();
    toggle();
  };
  const planUpgradeUrl = '/settings?tab=company-info&to=billing';
  const handleMoveToBilling = () => {
    history.push(planUpgradeUrl);
  };
  return (
    <Modal
      centered
      open={opened}
      onClose={toggle}
      closeIcon={null}
      width={370}
      footer={
        <Stack gap='10px' mt='1.5rem'>
          <CustomButton
            size='md'
            bold
            fw='600'
            fullWidth
            variant={'contained'}
            onClick={handleMoveToBilling}
          >
            Upgrade plan
          </CustomButton>
          <CustomButton size='md' bold fullWidth onClick={close}>
            Close
          </CustomButton>
        </Stack>
      }
    >
      <div className='d-flex align-items-center justify-content-center'>
        <svg
          width='28'
          height='28'
          viewBox='0 0 28 28'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M14 0C6.272 0 0 6.272 0 14C0 21.728 6.272 28 14 28C21.728 28 28 21.728 28 14C28 6.272 21.728 0 14 0ZM14 21C13.23 21 12.6 20.37 12.6 19.6V14C12.6 13.23 13.23 12.6 14 12.6C14.77 12.6 15.4 13.23 15.4 14V19.6C15.4 20.37 14.77 21 14 21ZM15.4 9.8H12.6V7H15.4V9.8Z'
            fill='#F8B636'
          />
        </svg>
      </div>

      <Typography bold align='center' mt='21px' size='lg' px='.9rem'>
        This feature is unavailable for your plan. Upgrade to access this
        feature.
      </Typography>
    </Modal>
  );
};

export default PlanRestrictionModal;
