import {
  // excludedRoutes,
  getModuleRoutes
} from './permissions';
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
axios.defaults.baseURL = baseUrl;

const createInstance = axios.create({
  baseURL: baseUrl
});

createInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    const currentUrl = window.location.href;

    if (config?.params) {
      config.params['x-referer'] = currentUrl;
    } else {
      config.params = { 'x-referer': currentUrl };
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const excludeForAuth = [
  'login',
  'signup',
  'privacy-policy',
  'forgot-password',
  'forgot-password-confirm',
  'terms',
  'password-reset',
  'verify-invite',
  'public-reference'
];
createInstance.interceptors.response.use(
  (response) => {
    if (response?.status >= 500) {
      window.open('/404');
    }
    return response;
  },
  (error) => {
    if (
      // excludedRoutes.includes(window.location.pathname) === false &&
      error?.response?.data?.detail ===
        'Access denied. Kindly contact admin.' &&
      getModuleRoutes().includes(window.location.pathname) === false
    ) {
      window.location.replace('/unauthorized-access');
    }
    const location = window.location.href;
    const locationIncludesExcluded = excludeForAuth.some((item) => {
      return location.includes(item);
    });
    if (
      locationIncludesExcluded ||
      window.location.href.includes('signup') ||
      window.location.href.includes('login')
    ) {
      return Promise.reject(error);
    }
    if (error?.response?.status === 401) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('business_domain');
      localStorage.removeItem('user');
      if (
        window.location.href.split('/').pop() !== 'login?reason=session_expired'
      ) {
        window.location = '/login?reason=session_expired';
      }
    }

    return Promise.reject(error);
  }
);

export function getBaseDomainURL() {
  const domain = localStorage.getItem('business_domain');
  const baseDomainURL = `/c/${domain}`;
  return baseDomainURL;
}

export default createInstance;
