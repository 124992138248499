import { useState } from 'react';
import {
  Label,
  SubmitButton,
  PolicyDiv,
  SignUpPrompt,
  PasswordTooTip,
  ShowPassword,
  ServerError,
  DividerHide,
  SignUpContainer
} from './styled.forms';
import {
  CircularProgress,
  TextField,
  Typography,
  Divider,
  Box
} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSignupStyles } from './Signup.style';
import { isWidthDown } from '@material-ui/core/withWidth';

function PasswordHint({ email, password }) {
  const classes = useSignupStyles();

  return (
    <ul className={classes.root}>
      <li>
        {password.length > 8 ? (
          <CheckCircleIcon fontSize='small' />
        ) : (
          <RadioButtonUncheckedIcon fontSize='small' />
        )}{' '}
        Your password must contain at least 8 characters
      </li>
      <li>
        {!email.includes(password) && password.length !== 0 ? (
          <CheckCircleIcon fontSize='small' />
        ) : (
          <RadioButtonUncheckedIcon fontSize='small' />
        )}{' '}
        Your password can't be too similar to your other personal information
      </li>
      {/* <li>Your password can't be a commonly used password</li> */}
      <li>
        {isNaN(password) ? (
          <CheckCircleIcon fontSize='small' />
        ) : (
          <RadioButtonUncheckedIcon fontSize='small' />
        )}{' '}
        Your password can't be entirely numeric
      </li>
    </ul>
  );
}
const SignUpInitial = ({ plan, serverErrors, formik, hasErrors, width }) => {
  const [showHint, setShowHint] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const classes = useSignupStyles();

  const isMobile = useMediaQuery({
    query: '(max-width: 640px)'
  });
  const history = useHistory();
  return (
    <SignUpContainer>
      {isMobile && (
        <ArrowBackIcon
          onClick={() => history.push('/signup')}
          className='back'
        />
      )}
      <Typography className='welcome'>
        {isMobile ? 'Create Your Bizedge Account ' : plan}
      </Typography>
      {/* <Typography className='other'>
                  Let's get right into business without delay.
                </Typography> */}
      <Typography className='future'>
        Let's journey into the future together
      </Typography>
      {/* <form onSubmit={formik.handleSubmit}> */}
      {serverErrors.password || serverErrors.email ? (
        <ServerError>
          <Typography component='span'>
            {serverErrors.email || serverErrors.password}
          </Typography>
        </ServerError>
      ) : null}
      <form onSubmit={formik.handleSubmit}>
        {/* =================== EMAIL ================== */}
        <Box className={classes.inputWrapper}>
          <TextField
            color='secondary'
            error={hasErrors('email')}
            fullWidth={true}
            label='Email'
            size={isWidthDown('lg', width) ? 'small' : 'small'}
            variant='outlined'
            placeholder='Email'
            type='email'
            name='email'
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            haserror={hasErrors('email')}
            FormHelperTextProps={{ error: hasErrors('email') }}
            helperText={hasErrors('email') ? formik.errors.email : null}
          />
        </Box>

        <Box className={classes.inputWrapper}>
          <Label>
            <PasswordTooTip
              open={showHint}
              placement='bottom-start'
              title={
                <PasswordHint
                  email={formik.values.email}
                  password={formik.values.password}
                />
              }
            >
              {/* =================== PASSWORD ================== */}
              <TextField
                color='secondary'
                error={hasErrors('password')}
                fullWidth={true}
                label='Password'
                size={isWidthDown('lg', width) ? 'small' : 'small'}
                variant='outlined'
                placeholder='Password'
                type={showPassword ? 'text' : 'password'}
                name='password'
                value={formik.values.password}
                onFocus={() => setShowHint(true)}
                onBlur={(e) => {
                  setShowHint(false);
                  return formik.handleBlur(e);
                }}
                onChange={formik.handleChange}
                haserror={hasErrors('password')}
                FormHelperTextProps={{
                  error: hasErrors('password')
                }}
                helperText={
                  hasErrors('password') ? formik.errors.password : null
                }
              />
            </PasswordTooTip>

            <ShowPassword onClick={() => setShowpassword(!showPassword)}>
              {showPassword ? 'Hide' : 'Show'}
            </ShowPassword>
          </Label>
        </Box>

        <SubmitButton
          disabled={
            !isNaN(formik.values.password) ||
            formik.values.password.length < 8 ||
            formik.values.email.includes(formik.values.password)
          }
          type='submit'
          onClick={formik.handleSubmit}
          startIcon={
            formik.isSubmitting ? (
              <CircularProgress size={25} style={{ color: 'white' }} />
            ) : null
          }
        >
          {formik.isSubmitting ? null : 'Sign Up'}
        </SubmitButton>
      </form>
      <PolicyDiv>
        By signing up, you are indicating that you have read and agreed to the
        {/* <br /> */}{' '}
        <Link to='/terms' className='link'>
          Terms of use
        </Link>{' '}
        and{' '}
        <Link to='/privacy-policy' className='link'>
          Privacy Policy
        </Link>
      </PolicyDiv>
      <DividerHide className={`${classes.divider} mobile-hide`}>
        <Divider />
      </DividerHide>
      <SignUpPrompt>
        Already have an account? <Link to='/'>Sign in</Link>
      </SignUpPrompt>
      {/* </form> */}
    </SignUpContainer>
  );
};

export default SignUpInitial;
