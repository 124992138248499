/* eslint-disable no-dupe-keys */

import { SubmitButton } from './styled.forms';
import {
  CircularProgress,
  TextField,
  Typography,
  Divider,
  Box,
  FormGroup,
  Button
} from '@material-ui/core';
import {
  getBilling,
  billingUpdateSetup,
  verifyPayment,
  verifyCouponCode
} from '../../api/billing';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';
import { usePaystackPayment } from 'react-paystack';
import { useFormik } from 'formik';
import { AntSwitch, useSignupStyles } from './Signup.style';

const BuyNow = ({
  setIsLoading,
  businessName,
  businessId,
  email,
  formik,
  setFeedback,
  handleClick
}) => {
  const classes = useSignupStyles();
  const queryClient = useQueryClient();
  const [value, setValue] = useState(0);
  const [counter, setCounter] = useState();
  const [isQuarterly, setIsQuarterly] = useState([7500]);
  const [isMonthly, setIsMonthly] = useState([5000]);
  const [buyNowStep, setBuyNowStep] = useState(1);
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  const [discount, setDiscount] = useState();
  const [isEmpty, setIsEmpty] = useState(false);
  const [isCheckingCoupon, setCheckingCoupon] = useState(false);
  const [numOfEmp, setNumOfEmp] = useState();
  const [employeeCount, setEmployeeCount] = useState();
  const [isRecurring, setIsRecurring] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPaymentLoading] = useState(false);

  const { data: billingData, isSuccess: successBilling } = useQuery(
    ['current-billing'],
    getBilling
  );

  useEffect(() => {
    const newData = () => {
      if (successBilling && billingData) {
        setCounter(billingData?.num_employees);
        setEmployeeCount(billingData?.num_employees);
        setIsRecurring(billingData && billingData.recurring);
        setValue(billingData?.frequency === 'monthly' ? 0 : 1);
        setNumOfEmp(billingData?.num_employees);
      }
    };
    newData();
  }, [successBilling, billingData]);

  useEffect(() => {
    if (value === 0) {
      setIsMonthly(employeeCount * 500);
    } else if (value === 1) {
      // let amount = billingData?.is_first_subcription
      //   ? employeeCount * 250 * 3
      //   : employeeCount * 500 * 3;
      let amount = employeeCount * 500 * 3;
      if (discount) {
        let discountAmount = (discount / 100) * amount;
        setIsQuarterly(amount - discountAmount);
      } else {
        setIsQuarterly(amount);
      }
    }
  }, [billingData, employeeCount, value, discount]);

  const handleChange = (e) => {
    const regEx = /^[0-9\b]+$/;
    if (e.target.value === '' || regEx.test(e.target.value)) {
      setNumOfEmp(e.target.value);
      setEmployeeCount(e.target.value);
      setIsEmpty(false);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    // setCounter(billingData?.num_employees);
    setIsMonthly(isMonthly);
    setIsQuarterly(isQuarterly);
  };

  const handleChangeRecurring = () => {
    setIsRecurring(!isRecurring);
  };
  const handleProceed = () => {
    setBuyNowStep(2);
  };

  const handleBack = () => {
    setBuyNowStep(1);
  };
  const onSuccess = (reference) => {
    // The payment reference was successfully created on Paystack's side
    if (reference.status === 'success') {
      setIsLoading(true);

      const payload = {
        reference: billingData && billingData.payment_reference // Reference to verify the payment
      };

      // Poll the backend at intervals to check if the payment was successfully processed
      // (It may take some time for the backend to confirm the payment status)
      const confirmPayment = setInterval(async () => {
        const res = await verifyPayment(payload);

        // If the payment is successfully verified (res.status is truthy)
        if (res.status) {
          clearInterval(confirmPayment); // Stop further polling
          setIsLoading(false);

          // Store a success message in localStorage for later use
          window.localStorage.setItem(
            'upgrade',
            JSON.stringify({
              toast: true,
              message:
                'Your subscription for Premium Edge was successful. You can now access all the modules.'
            })
          );

          // Redirect the user to the dashboard
          window.location.href = '/dashboard';
        }
      }, 5000);
    }
  };

  const onClose = () => {};

  const config = {
    email,
    amount: value === 0 ? Number(isMonthly) * 100 : Number(isQuarterly) * 100,
    reference: billingData && billingData.payment_reference,
    channels: ['card'],
    metadata: {
      bizedge_billing_type: 'billing.buy_now_subscription',
      bizedge_business_id: businessId && businessId,
      country: formik.values.country,
      // currency: props.planCode?.pricing?.currency.toUpperCase(),
      custom_fields: [
        {
          display_name: 'Business ID',
          variable_name: 'Business ID',
          value: businessId && businessId
        },
        {
          display_name: 'Business Name',
          variable_name: 'Business Name',
          value: businessName && businessName
        }
      ]
    },
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  };
  const initializePayment = usePaystackPayment(config);

  const couponSchema = yup.object({
    coupon_code: yup
      .string()
      .required('Coupon code is required')
      .min(12, 'Minimum of 12 characters')
      .max(12, 'Invalid coupon code entered')
  });

  const couponForm = useFormik({
    initialValues: {
      coupon_code: ''
    },
    validationSchema: couponSchema
    // onSubmit: handleVerifyCoupon
  });

  const handleVerifyCoupon = async () => {
    const { values } = couponForm;
    setCheckingCoupon(true);
    try {
      const coupon = await verifyCouponCode(values);
      if (coupon.can_use) {
        setDiscount(coupon.coupon_value);
        setIsValidCoupon(true);
        setFeedback({
          severity: 'success',
          message: 'Coupon code validated'
        });
      } else {
        setFeedback({
          severity: 'error',
          message: 'Enter a valid coupon code'
        });
      }
      setCheckingCoupon(false);
    } catch (error) {
      setFeedback({
        severity: 'error',
        message: error.message
      });
      setCheckingCoupon(false);
    }
    handleClick();
  };

  const handleBuyNow = async () => {
    if (!numOfEmp) {
      setIsEmpty(true);
      return;
    } else {
      setIsEmpty(false);
    }
    let frequency = value === 0 ? 'monthly' : 'quarterly';
    setIsSubmitted(true);
    try {
      const payload = {
        frequency,
        num_employees: Number(employeeCount),
        recurring: isRecurring
      };
      const data = await billingUpdateSetup(payload);
      if (data) {
        setNumOfEmp(data.num_employees);
        queryClient.refetchQueries(['current-billing']);
        initializePayment(onSuccess, onClose);
        setIsSubmitted(false);
      }
    } catch (error) {
      setIsSubmitted(false);
    }
  };

  return (
    <>
      {buyNowStep === 1 ? (
        <>
          <Box className='test'>
            <Typography variant='h6'>Purchase Premium Edge</Typography>
            <Box className='divider'>
              <Divider />
            </Box>
            <Box className='employeeNumber'>
              <Typography variant='subtitle2'>
                How many employees do you have?
              </Typography>
              <TextField
                type='text'
                size='small'
                fullWidth
                variant='outlined'
                color='secondary'
                placeholder='Enter the number of employees'
                value={numOfEmp}
                onChange={(event) => handleChange(event)}
                error={
                  numOfEmp && numOfEmp < counter ? true : isEmpty ? true : false
                }
                helperText={
                  numOfEmp && numOfEmp < counter
                    ? `You have ${counter} ${
                        counter > 1 ? 'employees' : 'employee'
                      }, you cannot pay for less`
                    : isEmpty
                    ? 'Number of employee cannot be empty'
                    : null
                }
              />
            </Box>
            <Box className='divider'>
              <Divider />
            </Box>
            <Grid
              container
              alignItems='center'
              style={{
                margin: '.8em 0px',
                justifyContent: 'space-between'
              }}
            >
              <Grid item md={5} className='tier'>
                <Typography variant='subtitle1'>
                  Select your billing cycle?
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Box className={classes.plan}>
                  <Tabs
                    classes={{ root: classes.tabsRoot }}
                    value={value}
                    onChange={handleChangeTab}
                  >
                    <Tab
                      classes={{ root: classes.tabRoot }}
                      style={{ minWidth: '50%' }}
                      label='Monthly'
                    />
                    <Tab
                      classes={{ root: classes.tabRoot }}
                      style={{ minWidth: '50%' }}
                      label='Quarterly'
                    />
                  </Tabs>
                </Box>
              </Grid>
            </Grid>
            <Box className='divider'>
              <Divider />
            </Box>
            <Typography
              variant='subtitle1'
              className='summary'
              style={{ marginTop: '.6em' }}
            >
              Summary
            </Typography>

            <Box className='summaryWrapper'>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box style={{ width: '30%' }}>
                  <Typography variant='subtitle2'>No. of People</Typography>
                </Box>
                <Box style={{ width: '35%' }}>
                  <Typography variant='subtitle2'>
                    {value === 0 ? 'Monthly' : 'Quarterly'} amount
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box style={{ width: '30%' }} className={classes.people}>
                  <Typography variant='subtitle2'>
                    {employeeCount === '' ? 0 : employeeCount} People
                  </Typography>
                </Box>
                <Box style={{ width: '35%' }} className={classes.amount}>
                  <Typography variant='subtitle2'>
                    &#8358;{value === 0 ? isMonthly : isQuarterly}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {value !== 0 && (
              <Box className='employeeNumber'>
                <Typography variant='subtitle2'>Coupon Code</Typography>
                <Grid
                  container
                  alignItems='center'
                  style={{
                    justifyContent: 'space-between'
                  }}
                >
                  <TextField
                    type='text'
                    size='small'
                    variant='outlined'
                    color='secondary'
                    name='coupon_code'
                    id='coupon_code'
                    style={{
                      width: `${couponForm.errors.coupon_code ? '100%' : '69%'}`
                    }}
                    value={couponForm.values.coupon_code}
                    onChange={couponForm.handleChange}
                    error={
                      couponForm.touched.coupon_code &&
                      Boolean(couponForm.errors.coupon_code)
                    }
                    helperText={couponForm.errors.coupon_code}
                    placeholder='Enter valid coupon code'
                  />
                  {!couponForm.errors.coupon_code && (
                    <Typography
                      onClick={handleVerifyCoupon}
                      variant='text'
                      style={{
                        color: '#2898A4',
                        cursor: 'pointer',
                        background: '#EAF8FA',
                        borderRadius: '7px',
                        padding: '.75rem .5rem',
                        width: '30%',
                        textAlign: 'center'
                      }}
                    >
                      Verify Code
                    </Typography>
                  )}
                </Grid>
                <Grid
                  container
                  alignItems='center'
                  style={{
                    color: '#2898A4',
                    cursor: 'pointer',
                    marginTop: '.8em'
                  }}
                >
                  {isValidCoupon && (
                    <Typography
                      variant='text'
                      style={{
                        color: '#2898A4'
                      }}
                    >
                      Code applies {discount}% discount to Quarterly Plan
                    </Typography>
                  )}
                  {isCheckingCoupon && (
                    <CircularProgress
                      disableShrink
                      size={25}
                      style={{ color: '#2898a4' }}
                    />
                  )}
                </Grid>
              </Box>
            )}
            <FormGroup>
              <Box component='div' style={{ margin: '.8em 0px 1.5em' }}>
                <Typography component='div'>
                  <Grid
                    component='label'
                    container
                    alignItems='center'
                    spacing={1}
                  >
                    <Grid item>
                      <AntSwitch
                        checked={isRecurring}
                        onChange={handleChangeRecurring}
                        name='recurring'
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant='subtitle2'>
                        Make payment recurring{' '}
                        {value === 0 ? 'monthly' : 'quarterly'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
              </Box>
            </FormGroup>
            <Box style={{ marginTop: '1em' }}>
              <Button
                variant='contained'
                color='secondary'
                fullWidth
                onClick={handleProceed}
                disabled={isSubmitted}
              >
                {isSubmitted ? (
                  <CircularProgress style={{ color: 'white' }} size={20} />
                ) : (
                  'Proceed'
                )}
              </Button>
              <Link to='/dashboard'>
                <Typography variant='subtitle2' className='trial'>
                  Cancel
                </Typography>
              </Link>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Grid container spacing={1} alignItems='center'>
            <Grid item>
              <IconButton onClick={handleBack}>
                <KeyboardBackspaceIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography>Back</Typography>
            </Grid>
          </Grid>
          <Typography className='welcome'>Purchase Summary</Typography>
          <Box>
            <Grid container className={classes.summary} spacing={1}>
              <Grid item md={6}>
                <Typography variant='subtitle2'>Total People</Typography>
                <Typography variant='subtitle1'>
                  {employeeCount} People
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant='subtitle2'>Billing Cycle</Typography>
                <Typography variant='subtitle1'>
                  {value === 0 ? 'Monthly' : 'Quarterly'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className='paymentPreview'>
            <Typography variant='subtitle2'>
              {value === 0 ? 'Monthly' : 'Quarterly'} Payment
            </Typography>
            <Box className='payment'>
              <Typography>
                &#8358;{value === 0 ? isMonthly : isQuarterly}
              </Typography>
            </Box>
          </Box>
          {/* <Box>
                        <Grid container className={classes.recurring}>
                          <Grid item>
                            <AntSwitch onChange={handleSwitchRecurring} />
                          </Grid>
                          <Grid item>
                            <Typography variant='subtitle2'>
                              Make Payment Recurring Monthly
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box> */}
          <Grid container>
            <Grid item sm={12}>
              <SubmitButton onClick={handleBuyNow}>
                {isPaymentLoading ? (
                  <CircularProgress style={{ color: 'white' }} size={20} />
                ) : (
                  'Buy Now'
                )}
              </SubmitButton>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default BuyNow;
