import { CustomSelect, CustomTextField } from 'components/Inputs';
import Group from 'components/StyledComponents/ButtonGroup';
import { useFormikContext } from 'formik';
import { assetStatus, conditions } from './data';
// import { addAsset, addAttachment, getAddAssetTypes } from 'api/asset'
import AssetType from './AssetType';
import { provideErrorInfo } from './data';
import { setValue } from './helper';
const AssetForm = ({ locations = [] }) => {
  const { setFieldValue, errors, touched, handleBlur, values } =
    useFormikContext();

  const handleChange = (name, isFormat) => {
    return isFormat
      ? setValue(setFieldValue, name, (event) => event.target?.value)
      : setValue(setFieldValue, name);
  };

  // console.log({ values }, 'values-types-2');

  return (
    <>
      <AssetType
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
        setFieldValue={setFieldValue}
        values={values}
        name='asset_type'
      />
      <CustomTextField
        size='small'
        label='Enter Asset Name'
        value={values.name}
        name='name'
        onChange={handleChange('name', true)}
        onBlur={handleBlur}
        {...provideErrorInfo(errors.name, touched.name)}
      />
      {/* <CustomTextField size='small' /> */}
      <CustomSelect
        size='small'
        value={values.location}
        label='Asset Location'
        name='location'
        onChange={handleChange('location', true)}
        options={locations}
        id='auto-complete'
        onBlur={handleBlur}
        {...provideErrorInfo(errors.location, touched.location)}
      />
      <Group>
        <CustomSelect
          name='status'
          size='small'
          options={assetStatus}
          value={values.status}
          label='Status'
          onChange={handleChange('status', true)}
          onBlur={handleBlur}
          {...provideErrorInfo(errors.status, touched.status)}
        />
        <CustomSelect
          name='asset_condition'
          size='small'
          options={conditions}
          value={values.asset_condition}
          label='Condition'
          onChange={handleChange('asset_condition', true)}
          onBlur={handleBlur}
          {...provideErrorInfo(errors.asset_condition, touched.conditions)}
        />
      </Group>
    </>
  );
};

export default AssetForm;
