// import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useQuery } from 'react-query';
import { getToken } from 'utilities/utils';
import { businessUser } from 'api/settings';

const PermissionContext = React.createContext();
PermissionContext.displayName = 'PermissionContext';

const useStyles = makeStyles((theme) => ({
  loader: {
    width: '20%',
    margin: '10em auto',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '2em auto'
    }
  }
}));

export default function PermissionProvider(props) {
  const classes = useStyles();
  const access_token = getToken();

  const result = useQuery({
    queryKey: 'user-permission',
    queryFn: businessUser,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    enabled: Boolean(access_token),
    onSuccess: (res) => {
      const user = JSON.parse(localStorage.getItem('user_information'));
      if (!res.is_owner && !user) {
        localStorage.setItem('user_information', JSON.stringify(res));
      }
    }
  });

  if (access_token && result.isLoading) {
    return (
      <div className={classes.loader}>
        <img
          src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638526892/Bizedge_Frontend_Assets/BizLoader_dzmb4i.gif'
          width={250}
          height={250}
          alt='loading spinner'
        />
      </div>
    );
  }
  return <PermissionContext.Provider value={result} {...props} />;
}

export function usePermission() {
  const context = React.useContext(PermissionContext);
  if (context === undefined) {
    throw new Error(`usePermission must be used within a PermissionProvider`);
  }
  return context;
}
