import { createContext, useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getBusinessDetail } from '../api/settings';
import { getToken } from 'utilities/utils';

export const BusinessDetailContext = createContext();

export const BusinessIdContext = createContext();

// const BusinessIdProvider = () => {
//   return <BusinessIdContext.Provider></BusinessIdContext.Provider>;
// };

const BusinessDetailProvider = ({ children }) => {
  let token = getToken();
  const result = useQuery({
    queryKey: ['business-detail'],
    queryFn: getBusinessDetail,
    enabled: Boolean(token)
  });
  const queryClient = useQueryClient();

  return (
    <BusinessDetailContext.Provider
      value={{ ...result, queryClient: queryClient }}
    >
      {children}
    </BusinessDetailContext.Provider>
  );
};

export default BusinessDetailProvider;

export const useBusinessDetail = () => {
  const context = useContext(BusinessDetailContext);
  if (context === undefined) {
    throw new Error(
      `useBusinessDetail must be used within a BusinessDetailContext`
    );
  }
  return context;
};
export const useBusinessId = () => {
  const context = useContext(BusinessIdContext);
  if (context === undefined) {
    throw new Error(`useBusinessId must be used within a BusinessIdContext`);
  }
  return context;
};
