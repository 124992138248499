/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, TextField, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import ErrorIcon from '@material-ui/icons/Error';
import { useFormik } from 'formik';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useJwt } from 'react-jwt';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../Auth/auth-context';
import { login } from '../../api/common';
import SnackBar from '../../common/SnackBar';
import GoogleDownload from '../../common/images/GoogleDownload.svg';
import IOSDownload from '../../common/images/IOSDownload.svg';
import BizedgeImage from '../../common/images/bizedgeRound.svg';
import MyedgeImage from 'common/images/myedge-logo.svg';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import {
  AuthFormContainer,
  ForgotPassword,
  FormDescription,
  // FormDivAuth,
  // FormHeader,
  // FormDescription,
  Label,
  // ServerError,
  SessionExpired,
  ShowPassword,
  // ResendConfirmation,
  SignUpPrompt,
  SubmitButton
} from './styled.forms';
import validator from './validator';
import { CustomButton } from 'components/Button';
import { Divider, Stack } from '@mui/material';
import { CustomText } from 'common/Reusables';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const token = localStorage.getItem('access_token');

const initialValues = {
  email: '',
  password: ''
};

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    marginBottom: '1em'
  },
  divider: {
    margin: '2em 0'
  },
  resend: {
    '& a': {
      color: theme.palette.secondary.main,
      fontSize: '11px',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  }
}));

// export const notify = () =>
//   toast.info('Your session has expired, please login to continue');

function Login({ width }) {
  const userAuth = useAuth();
  const history = useHistory();
  const classes = useStyles();
  const { search } = useLocation();
  const { reason } = qs.parse(search);
  const { isExpired } = useJwt(token);

  const isMobile = useMediaQuery({
    query: '(max-width: 640px)'
  });
  useEffect(() => {
    const checkTokenExpiration = () => {
      if (isExpired || !token) {
        return <></>;
      }
    };
    checkTokenExpiration();
  }, []);

  const [serverErrors, setServerErrors] = useState({
    non_field_errors: '',
    code: ''
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (payload) => {
    setServerErrors('');
    try {
      const token = localStorage.getItem('access_token');
      if (token) {
        localStorage.clear();
      }
      const user = await login(payload);
      if (user) {
        return userAuth.refetch();
      }
    } catch (error) {
      setServerErrors({ ...serverErrors, ...error });
    }
  };
  const formik = useFormik({
    initialValues,
    validate: validator,
    onSubmit: handleSubmit
  });

  const hasErrors = (field) => {
    return formik.touched[field] && formik.errors[field];
  };

  // const disableButton =
  //   hasErrors('email') || hasErrors('password') || formik.isSubmitting;

  // this is needed because user info is need to be fetched before moving to other pages;
  if (userAuth.data) {
    const businessStatus = userAuth?.data?.business_onboarding_status;
    const isBusinessInCreateMode = businessStatus === 'create';
    const uri = ['create', 'setup', 'unapplicable', ''].includes(
      userAuth?.data?.business_onboarding_status
    )
      ? '/dashboard'
      : '/login';

    // push to
    if (isBusinessInCreateMode) {
      return history.push(uri);
    } else {
      return history.replace(uri);
    }
  }

  const handleOpenEmployee = () => {
    const url =
      process.env.REACT_APP_ENV === 'prod'
        ? 'https://my.bizedgeapp.com/'
        : 'https://mydev-app.coolowo.com/';
    return window.open(url);
  };

  return (
    <>
      {(serverErrors?.non_field_errors?.length > 0 || serverErrors?.detail) && (
        <SnackBar severity='error'>
          {serverErrors?.non_field_errors
            ? serverErrors?.non_field_errors[0]
            : serverErrors?.detail}
        </SnackBar>
      )}
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <title>Log in to BizEdge</title>
        <meta property='og:title' content='Log in to BizEdge' />
        <meta
          name='description'
          content='Log in to the all in one HR, Payroll and Productivity Solution that lets you focus on people, not processes.'
        />
        <meta
          property='og:description'
          content='Log in to the all in one HR, Payroll and Productivity Solution that lets you focus on people, not processes.'
        />
      </Helmet>

      <AuthFormContainer>
        <Box className='download'>
          <Typography className='downloadCopy'>
            Download the Bizedge app and stay connected always.
          </Typography>
          <Grid
            container
            spacing={1}
            justifyContent='center'
            alignItems='center'
          >
            <Grid item sm={6}>
              <img src={IOSDownload} alt='' />
            </Grid>
            <Grid item sm={6}>
              <img src={GoogleDownload} alt='' />
            </Grid>
          </Grid>
        </Box>
        <Box className='c-sideLeft'>
          <Box className='container'>
            <Box className='info'>
              <img src={BizedgeImage} alt='' />
            </Box>
            <Typography variant='subtitle2' className='intro'>
              All In One for Ease and Effectiveness
            </Typography>
          </Box>
        </Box>

        <Box className='c-sideRight' maxWidth={'800px'} mx='auto'>
          <Box className='c-sideRight-logo'>
            <img
              src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638786088/Bizedge_Frontend_Assets/Bizedge_logo_axcwlg.svg'
              alt='logo'
              style={{ width: '100px', height: '100px', marginLeft: '9%' }}
              className='logo'
            />
          </Box>
          <Box className='c-sideRight-form-login'>
            <Typography className='welcome'>Welcome back</Typography>
            {isMobile && (
              <FormDescription>
                Get right into business without delay{' '}
              </FormDescription>
            )}
            {reason === 'session_expired' && (
              <SessionExpired>
                <Typography variant='subtitle1' className='heading'>
                  You have been signed out{' '}
                  <ErrorIcon
                    htmlColor='#2898A4'
                    fontSize='10'
                    style={{ fontSize: 15, verticalAlign: 'middle' }}
                  />
                </Typography>
                <Typography variant='subtitle2' className='sub-text'>
                  Due to a period of inactivity, you have been automatically
                  signed out. You can sign back in below
                </Typography>
              </SessionExpired>
            )}

            {/* <Typography className='other'>
              Let's get right into business delay.
            </Typography> */}
            <form onSubmit={formik.handleSubmit}>
              {/* ==================== EMAIL ================== */}
              <Box className={classes.inputWrapper}>
                <TextField
                  color='secondary'
                  error={hasErrors('email')}
                  fullWidth={true}
                  label='Email'
                  size={isWidthDown('lg', width) ? 'medium' : 'small'}
                  variant='outlined'
                  placeholder='Email'
                  type='email'
                  name='email'
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.setFieldValue('email', e.target.value);
                    setServerErrors('');
                  }}
                  haserror={hasErrors('email')}
                  FormHelperTextProps={{ error: hasErrors('email') }}
                  helperText={hasErrors('email') ? formik.errors.email : null}
                  className='login-email'
                />
              </Box>

              {/* ==================== PASSWORD ================== */}
              <Box>
                <Box className={classes.inputWrapper}>
                  <Label>
                    <TextField
                      color='secondary'
                      error={hasErrors('password')}
                      fullWidth={true}
                      label='Password'
                      size={isWidthDown('lg', width) ? 'medium' : 'small'}
                      variant='outlined'
                      placeholder='Password'
                      type={showPassword ? 'text' : 'password'}
                      name='password'
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.setFieldValue('password', e.target.value);
                        setServerErrors('');
                      }}
                      haserror={hasErrors('password')}
                      FormHelperTextProps={{ error: hasErrors('password') }}
                      helperText={
                        hasErrors('password') ? formik.errors.password : null
                      }
                    />
                    <ShowPassword
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? 'Hide' : 'Show'}
                    </ShowPassword>
                  </Label>
                </Box>
                <div>
                  <ForgotPassword textAlign={'left !important'}>
                    <Link to='/forgot-password'>Forgot your password?</Link>
                  </ForgotPassword>
                </div>
              </Box>
              <SubmitButton
                disabled={formik.isSubmitting}
                type='submit'
                onClick={formik.handleSubmit}
              >
                {formik.isSubmitting ? (
                  <CircularProgress size={25} style={{ color: 'white' }} />
                ) : (
                  'Sign In'
                )}
              </SubmitButton>

              <Box>
                <SignUpPrompt>
                  I don't have an account? <Link to='/signup'>Sign up</Link>
                </SignUpPrompt>
              </Box>
              {/* <button onClick={notify}>Notify!</button> */}
              <Stack
                alignItems='center'
                direction={{ base: 'column', md: 'row' }}
                divider={<Divider flexItem orientation='vertical' />}
                justifyContent={'center'}
                sx={{
                  border: '1px solid #E1E1E1',
                  borderRadius: '10px',
                  p: '10px 24px',
                  gap: '.8rem',
                  mt: '2rem'
                }}
              >
                <img
                  src={MyedgeImage}
                  alt='myedge app logo - the employee self service app'
                />
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  alignItems={'center'}
                >
                  <CustomText
                    fontWeight={'600'}
                    color='#171717'
                    fontSize='14px'
                    textAlign={'center'}
                  >
                    Log in to Employee Self Service App
                  </CustomText>
                  <CustomButton
                    bold
                    variant={'text'}
                    onClick={handleOpenEmployee}
                  >
                    Click here <TrendingFlatIcon />
                  </CustomButton>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Box>
      </AuthFormContainer>
    </>
  );
}

export default withWidth()(Login);
