import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/swiper.min.css';

import React, {
  lazy,
  Suspense,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import Heap from 'react-heap';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import AssetRoutes from './Assets/routes.assets';
import FleetRoutes from './Fleet/routes.fleet';
import OfflineDetector from 'components/OfflineDetector';
import AccountVerification from './Onboarding/AccountVerification';
import SignupConfirm from './Onboarding/ConfirmSignup';
import ConfirmationMail from './Onboarding/EmailConfirmation';

// import ConfirmationMail from './Onboarding/ConfirmationMail';
// import AccountVerification from './Onboarding/AccountVerification';
// import SignupConfirm from './Onboarding/ConfirmSignup';

import PasswordResetSuccess from './Onboarding/PasswordResetSuccess';
import PageNotFound from './PageNotFound';
import AuthorizedAccess from './PermissionGate/UnauthorizedAccess.jsx';
import Privacy from './PrivacyPolicy/index';
import InviteVerification from './Settings/InviteVerification';
import Termspage from './Terms';

// import SettingRoutes from './Settings/routes.settings';
// import ProfileRoutes from './Profile/routes.profile';
// import BillingRoutes from './Billing/routes.billing';
// import PayrollRoutes from './Payroll/routes.payroll';
// import AccountingRoutes from './Account/routes.accounting';
// import Onboarding from './HR/On-off-boarding/Onboarding';
// import AppPrivateRoute from './Auth/AppPrivateRoute';
import { createTheme, CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BillingGate from './BillingGate';
import BuyNowGate from './BuyNowGate';
import appTheme from './common/theme';
import {
  default as AccountConfirmationPage,
  default as ForgotPasswordPage,
  default as LoginPage,
  default as PasswordResetPage,
  default as SignupPage
} from './Onboarding';
import PermissionGate from './PermissionGate';
import UpgradePlanGate from './UpgradePlanGate';
import { clarity } from 'react-microsoft-clarity';
import SignUp from './Onboarding/NewSignUpFlow/signup';
import JobListingPublic from 'Recruit/PublicView';
import { ThemeProvider } from 'styled-components';
import { BillingProvider } from './hooks/useBilling';
import SingleModule from './Settings/Human resources/SingleModule';
import Maintenance from 'Onboarding/Maintenance/Maintenance';
import useServerStatus from 'hooks/useServerStatus';
import { Toaster } from 'sonner';
import PlanRestriction from 'common/PlanRestriction';
import { useAuth } from 'Auth/auth-context';
const theme = createTheme(appTheme);

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // cancel all retries if error code is 402 , 402 is  our subscription plan does not grant you access to this module.
      retry: (failureCount, error) => {
        // Stop retries for HTTP status 402
        if (error?.response?.status === 402) {
          return false;
        }
        // Retry up to 3 times for other errors
        return failureCount < 3;
      }
    }
  }
});

const useStyles = makeStyles({
  loader: {
    width: '20%',
    margin: '10em auto',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '2em auto'
    }
  },
  offlineWrapper: {
    position: 'fixed',
    top: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '300px',
    padding: '2px 20px',
    background: '#ffe7e7 !important',
    zIndex: '999999'
  }
});
const ReferenceRoute = lazy(() => import('Reference/routes'));
const OnboardingPage = lazy(() => import('./Onboarding'));
const QuickActions = lazy(() => import('./Onboarding/Dashboard'));
const BusinessSetup = lazy(() => import('./Onboarding/forms/BusinessSetup'));
const AddEmployeeNew = lazy(() => import('./HR/NewAddEmployee'));
const AddNewCompany = lazy(() => import('./Settings/Modals/AddNewCompany'));
const AddBusinessForm = lazy(() => import('./AddBusiness/AddBusinessForm'));
const BuyNow = lazy(() => import('./Billing/BuyNow'));
const Pricing = lazy(() => import('./Billing/PaymentModule'));
const Payment = lazy(() => import('./Billing/PaymentModule/Payment'));
const UpgradePlan = lazy(() => import('./Billing/Upgrade'));
const HR = lazy(() => import('./HR'));
const Manager = lazy(() => import('./Manager/Manager'));
const TimeAndAttendance = lazy(() => import('./TimeAndAttendance'));

// const TimeAndAttendanceProfile = lazy(() =>
//   import('./TimeAndAttendance/SingleEmployee')
// );

const TimeAndAttendanceWorkingDays = lazy(() =>
  import('./TimeAndAttendance/Settings/Working Days')
);

const TimeAndAttendanceLatnessPolicy = lazy(() =>
  import('./TimeAndAttendance/Settings/SettingsPage/LatenessPolicy')
);

const TimeAndAttendanceStartDate = lazy(() =>
  import('./TimeAndAttendance/Settings/SettingsPage/StartDate')
);

const CompanyLocation = lazy(() =>
  import('./TimeAndAttendance/Settings/SettingsPage/CompanyLocation')
);

const HrSetup = lazy(() => import('./HR/HrSetup'));
const Invites = lazy(() => import('./HR/Invites'));
const TurnoverReport = lazy(() => import('./HR/Report/TurnoverReport'));
const AgeProfile = lazy(() => import('./HR/Report/AgeProfile'));
const HeadCount = lazy(() => import('./HR/Report/Headcount/Headcount.jsx'));
const EmployeeCount = lazy(() => import('./HR/Report/EmployeeCount'));
const DiversityDemographic = lazy(() =>
  import('./HR/Report/Diversity/Diversity.jsx')
);
const Salaries = lazy(() => import('./HR/Report/Salaries'));
const SettingRoutes = lazy(() => import('./Settings/routes.settings'));
const ProfileRoutes = lazy(() => import('./Profile/routes.profile'));
const BillingRoutes = lazy(() => import('./Billing/routes.billing'));
const PayrollRoutes = lazy(() => import('./Payroll/routes.payroll'));
const RecruitRoutes = lazy(() => import('./Recruit/routes.recruit'));
const Onboarding = lazy(() => import('./HR/On-off-boarding/Onboarding'));
const AppPrivateRoute = lazy(() => import('./Auth/AppPrivateRoute'));
const PerformanceRoute = lazy(() => import('./Performance/routes.performance'));

const NewOnboardingModule = lazy(() =>
  import('./HR/NewOnboardingModule/OnboardingProcess')
);

const ContinueOnboardingModule = lazy(() =>
  import('./HR/NewOnboardingModule/OnboardingProcess/ContinueOnboard')
);

const ViewOnboarding = lazy(() =>
  import('./HR/NewOnboardingModule/ViewOnboard')
);

const ShiftRota = lazy(() => import('./ShiftManagement'));
const PublicReferenceRefereeResponses = lazy(() =>
  import('./Reference/Public/RefereeResponse/RefereeResponse')
);
const PublicReferenceAddReferee = lazy(() =>
  import('./Reference/Public/AddReferee/AddReferee')
);
function App() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const serverUp = useServerStatus();

  const user = localStorage.getItem('user');
  const { data } = useAuth();
  const domain = localStorage.getItem('business_domain');
  const [isUser, setIsUser] = useState({
    businessName: '',
    email: '',
    businessID: ''
  });

  useLayoutEffect(() => {
    if (
      domain === 'd741f131-55ff-4a11-b0b2-14e0c0ac10bb' &&
      !location.pathname.includes('payroll')
    ) {
      window.location.href = '/payroll?tab=home';
    }
    // eslint-disable-next-line
  }, [domain, user, data]);

  useEffect(() => {
    if (data && user && domain) {
      const user = data?.business_user_memberships?.filter(
        (item) => item?.business_id === domain
      );
      const businessName = user[0]?.business_name;
      setIsUser({ businessName, email: data?.email, businessID: domain });
    }
  }, [domain, user, data]);

  if (user && location.pathname === '/') {
    history.push('/dashboard');
  }

  if (serverUp === false) {
    return <Maintenance />;
  }

  return (
    <React.Fragment>
      <Toaster richColors position='bottom-center' />
      {process.env.REACT_APP_ENV === 'prod' && (
        <Heap
          appId='2856311930'
          userId={isUser?.email || domain}
          userData={{
            User_Id: domain,
            email: isUser?.email,
            Business_ID: isUser?.businessID,
            Bussiness_Name: isUser?.businessName
          }}
        />
      )}
      {process.env.REACT_APP_ENV === 'prod' && clarity.init('ptq7pb8i67')}
      <OfflineDetector />
      <Router>
        <Switch>
          <QueryClientProvider client={client}>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <CssBaseline />

                <Route exact path='/login'>
                  <LoginPage />
                </Route>
                <Route exact path='/signup'>
                  <SignupPage />
                </Route>
                <Route exact path='/premium/signup'>
                  <SignUp />
                </Route>
                <Route exact path='/forgot-password'>
                  <ForgotPasswordPage />
                </Route>
                <Route exact path='/forgot-password-confirm'>
                  <ConfirmationMail />
                </Route>
                <Route exact path='/signup/confirm'>
                  <SignupConfirm />
                </Route>
                <Route exact path='/account/verify'>
                  <AccountVerification />
                </Route>
                <Route exact path='/verify-invite'>
                  <InviteVerification />
                </Route>
                <Route exact path='/account/password-reset'>
                  <PasswordResetPage />
                </Route>
                <Route exact path='/password-reset/successful'>
                  <PasswordResetSuccess />
                </Route>
                <Route exact path='/account/confirmation/new'>
                  <AccountConfirmationPage />
                </Route>
                <Route exact path='/404'>
                  <PageNotFound />
                </Route>
                <Route exact path='/unauthorized-access'>
                  <AuthorizedAccess />
                </Route>
                <Route exact path='/privacy-policy'>
                  <Privacy />
                </Route>
                <Route exact path='/terms'>
                  <Termspage />
                </Route>
                <Route exact path='/recruit/:id'>
                  <JobListingPublic />
                </Route>
                <Suspense
                  fallback={
                    <div className={classes.loader}>
                      <img
                        src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638526892/Bizedge_Frontend_Assets/BizLoader_dzmb4i.gif'
                        width={250}
                        height={250}
                        alt='loading spinner'
                      />
                    </div>
                  }
                >
                  <>
                    <PlanRestriction />
                    <Route exact path='/public-reference/add-referee'>
                      <PublicReferenceAddReferee />
                    </Route>
                    <Route exact path='/public-reference/referee-attend'>
                      <PublicReferenceRefereeResponses />
                    </Route>
                    <AppPrivateRoute exact path='/' />
                    <AppPrivateRoute path='/dashboard'>
                      <BillingGate>
                        <BillingProvider>
                          <QuickActions />
                        </BillingProvider>
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/hr/report/turnover'>
                      <BillingGate>
                        <TurnoverReport />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/hr/report/employee-count'>
                      <BillingGate>
                        <EmployeeCount />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/hr/report/head-count'>
                      <BillingGate>
                        <HeadCount />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute
                      exact
                      path='/hr/report/diversity-demographic'
                    >
                      <BillingGate>
                        <DiversityDemographic />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/hr/report/age-profile'>
                      <BillingGate>
                        <AgeProfile />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/hr/report/salaries'>
                      <BillingGate>
                        <Salaries />
                      </BillingGate>
                    </AppPrivateRoute>
                    {/* <AppPrivateRoute exact path='/hr/compensation-history/:id'>
                      <BillingGate> 
                        <CompensationHistory />
                      </BillingGate>
                    </AppPrivateRoute> */}
                    {/* {(domain === 'df9cb1d8-9a53-425e-a737-cb7edfaeb6e2' ||
                      domain === '9d870f22-e732-4790-ae1b-1151308feae0' ||
                      window.location.host !== 'web.bizedgeapp.com') && (
                      <> */}
                    <AppPrivateRoute exact path='/shift-rota'>
                      <BillingGate>
                        <ShiftRota />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/time-and-attendance'>
                      <BillingGate>
                        <TimeAndAttendance />
                      </BillingGate>
                    </AppPrivateRoute>
                    {/* <AppPrivateRoute
                      exact
                      path='/time-and-attendance/employee/:id'
                    >
                      <BillingGate>
                        <TimeAndAttendanceProfile />
                      </BillingGate>
                    </AppPrivateRoute> */}
                    <AppPrivateRoute
                      exact
                      path='/time-and-attendance/settings/:type'
                    >
                      <BillingGate>
                        <TimeAndAttendanceWorkingDays />
                      </BillingGate>
                    </AppPrivateRoute>
                    {/* </>
                    )} */}
                    <AppPrivateRoute
                      exact
                      path='/time-and-attendance/lateness-policy'
                    >
                      {/* latenesspolicy */}
                      <BillingGate>
                        <TimeAndAttendanceLatnessPolicy />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute
                      exact
                      path='/time-and-attendance/start-date'
                    >
                      {/* startdate */}
                      <BillingGate>
                        <TimeAndAttendanceStartDate />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute
                      exact
                      path='/time-and-attendance/company-location'
                    >
                      <BillingGate>
                        <CompanyLocation />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute path='/business-setup'>
                      <BillingGate>
                        <BusinessSetup />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute path='/settings'>
                      <BillingGate>
                        <SettingRoutes base='/settings' />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/billing'>
                      <BillingRoutes base='/billing' />
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/billing/pricing'>
                      <UpgradePlanGate>
                        <BillingProvider>
                          <Pricing />
                        </BillingProvider>
                      </UpgradePlanGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/billing/pay'>
                      <UpgradePlanGate>
                        <BillingProvider>
                          <Payment />
                        </BillingProvider>
                      </UpgradePlanGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/billing/buy-now'>
                      <BuyNowGate>
                        <BillingProvider>
                          <BuyNow />
                        </BillingProvider>
                      </BuyNowGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/billing/upgrade-plan'>
                      <UpgradePlanGate>
                        <BillingProvider>
                          <UpgradePlan />
                        </BillingProvider>
                      </UpgradePlanGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/billing/extend-subscription'>
                      <UpgradePlanGate>
                        <BillingProvider>
                          <UpgradePlan />
                        </BillingProvider>
                      </UpgradePlanGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/settings/hr/:module'>
                      <BillingGate>
                        <SingleModule />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/business-registration'>
                      <OnboardingPage />
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/add-employee-new'>
                      <BillingGate>
                        <AddEmployeeNew />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/add-business'>
                      <BillingGate>
                        <AddBusinessForm />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/profile'>
                      {/* <BusinessUserProfile /> */}
                      <BillingGate>
                        <ProfileRoutes />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/profile/add-company'>
                      <BillingGate>
                        <AddNewCompany />
                      </BillingGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/hr'>
                      <PermissionGate path='access_hr'>
                        <BillingGate>
                          <HR />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/myteam'>
                      <PermissionGate path='access_hr'>
                        <BillingGate>
                          <Manager />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute path='/performance'>
                      <PermissionGate path='access_hr'>
                        <BillingGate>
                          <PerformanceRoute base='/performance' />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute path='/reference'>
                      <PermissionGate path='access_hr'>
                        <BillingGate>
                          <ReferenceRoute base='/reference' />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/recruit'>
                      <PermissionGate path='access_hr'>
                        <BillingGate>
                          <RecruitRoutes base='/recruit' />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/hr/setup'>
                      <PermissionGate path='access_hr'>
                        <BillingGate>
                          <HrSetup />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/invites'>
                      <PermissionGate path='access_hr'>
                        <BillingGate>
                          <Invites />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/hr/onboarding/:id'>
                      <PermissionGate path='access_hr'>
                        <BillingGate>
                          <Onboarding />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/hr/new-onboarding/:employee'>
                      <PermissionGate path='access_hr'>
                        <BillingGate>
                          <NewOnboardingModule />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/hr/continue-onboarding/:id'>
                      <PermissionGate path='access_hr'>
                        <BillingGate>
                          <ContinueOnboardingModule />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute exact path='/hr/view-onboarding/:id'>
                      <PermissionGate path='access_hr'>
                        <BillingGate>
                          <ViewOnboarding />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute path='/fleet'>
                      <PermissionGate path='access_vehicles'>
                        <BillingGate>
                          <FleetRoutes base='/fleet' />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute>
                    <AppPrivateRoute path='/assets'>
                      <PermissionGate path='access_assets'>
                        <BillingGate>
                          <AssetRoutes base='/assets' />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute>
                    {/* <AppPrivateRoute path='/accounts'>
                      <PermissionGate path='access_accounting'>
                        <BillingGate>
                          <AccountingRoutes base='/accounts' />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute> */}
                    <AppPrivateRoute path='/payroll'>
                      <PermissionGate path='access_payroll'>
                        <BillingGate>
                          <PayrollRoutes base='/payroll' />
                        </BillingGate>
                      </PermissionGate>
                    </AppPrivateRoute>
                  </>
                </Suspense>
                <ReactQueryDevtools />
              </ThemeProvider>
            </MuiThemeProvider>
          </QueryClientProvider>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
