import { useState, useEffect, useMemo } from 'react';
import qs from 'query-string';
import { useFormik } from 'formik';
import { AuthFormContainer } from './styled.forms';
import { Typography, Box, Snackbar } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import withWidth from '@material-ui/core/withWidth';
import { validateSignup } from './validator';
import { Redirect, useLocation } from 'react-router-dom';
import { createBusiness, signup } from '../../api/common';
import { useAuth } from '../../Auth/auth-context';
import BizedgeImage from '../../common/images/bizedgeRound.svg';
import BusinessRegistration from './BusinessRegistration';
import { useQueryClient } from 'react-query';
import { checkCompanyAddStatus } from '../../api/hr';
import ProgressBar from '../../HR/Home/ProgressBar';
import { Alert } from '@material-ui/lab';
import { useUTMParams } from 'hooks/useUTMParams';
import { useSignupStyles } from './Signup.style';
import BuyNow from './BuyNow';
import SignUpInitial from './SignUpInitial';

function useQueryPlan() {
  const { search } = useLocation();
  const { plan, from_app } = qs.parse(search) || {};
  return { plan, from_app };
}

const initialValues = {
  email: '',
  password: ''
};

const generateInternalUrlWithUTM = (baseUrl, params) => {
  if (!params) return baseUrl;
  const url = new URL(baseUrl, window.location.origin);
  if (params.utmSource) url.searchParams.set('utm_source', params.utmSource);
  if (params.utmMedium) url.searchParams.set('utm_medium', params.utmMedium);
  if (params.utmCampaign)
    url.searchParams.set('utm_campaign', params.utmCampaign);

  return url.pathname + url.search;
};

function Signup({ width }) {
  const result = useAuth();
  const classes = useSignupStyles();
  const queryClient = useQueryClient();
  const queryPlan = useQueryPlan();
  const [serverErrors, setServerErrors] = useState({
    password: ''
  });
  const [isComplete, setIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [businessId, setBusinessId] = useState('');
  const [isBusinessCreated, setIsBusinessCreated] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState({ severity: '', message: '' });
  const [completed, setCompleted] = useState(5);
  const [products, setProducts] = useState([]);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (isLoading) {
      setInterval(() => {
        setCompleted((prev) => (prev >= 95 ? prev : prev + 5));
      }, 5000);
    }
  }, [isLoading]);

  const handleSubmitBusiness = async (payload) => {
    const formData = new FormData();

    formData.append('name', payload?.name);
    payload?.logo && formData.append('logo', payload?.logo);
    payload?.avatar && formData.append('avatar', payload?.avatar);
    formData.append('type', payload?.type);
    formData.append('state', payload?.state);
    formData.append('phone_number', payload?.phone_number);
    formData.append('last_name', payload?.last_name);
    formData.append('first_name', payload?.first_name);
    formData.append('description', payload?.description);
    formData.append('country', payload?.country);
    formData.append('city', payload?.city);
    formData.append('address2', payload?.address2);
    formData.append('address1', payload?.address1);
    formData.append('size', payload?.size);
    formData.append('point_of_contact', payload?.point_of_contact);
    if (payload?.rating) {
      formData.append('rating', payload?.rating);
    }
    if (products.length > 0) {
      formData.append('interested_in', products.toString());
    }

    setIsLoading(true);
    try {
      const response = await createBusiness(formData);
      if (response) {
        let formData = new FormData();
        formData.append('task_id', response?.task_id);
        const BusinessCreate = setInterval(async () => {
          const newBusiness = await checkCompanyAddStatus(formData);
          if (newBusiness.status === 'SUCCESS') {
            setIsLoading(false);
            clearInterval(BusinessCreate);
            queryClient.refetchQueries(['user']);
            queryClient.refetchQueries(['business-detail']);
            queryClient.refetchQueries(['current-billing']);
            localStorage.setItem(
              'business_domain',
              newBusiness?.data.business_id
            );
            if (queryPlan.plan === 'buy_now') {
              setIsLoading(false);
              setBusinessName(newBusiness?.data?.name);
              setBusinessId(newBusiness?.data?.business_id);
              setIsBusinessCreated(true);
            } else {
              setIsLoading(false);
              window.location.href = '/dashboard';
            }
          }
        }, 5000);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const utmParams = useUTMParams();

  const urlWithUTM = useMemo(
    () => generateInternalUrlWithUTM('/business-registration', utmParams),
    [utmParams]
  );

  const handleSubmit = async (values) => {
    const queryString = new URLSearchParams(utmParams).toString();
    const business_plan =
      queryPlan.plan === 'buy_now'
        ? 'premium'
        : queryPlan.plan === 'free_trial'
        ? 'premium'
        : 'free';
    const subscription_info =
      queryPlan.plan === 'buy_now'
        ? {
            billing_buy_now: true,
            billing_frequency: 'monthly',
            billing_num_employees: 1
          }
        : queryPlan.plan === 'free_trial'
        ? {
            billing_buy_now: false,
            billing_frequency: 'monthly',
            billing_num_employees: 1
          }
        : null;
    const token = localStorage.getItem('access_token');
    if (token) {
      localStorage.clear();
    }
    const payload = {
      business_plan,
      subscription_info,
      email: values.email,
      password: values.password,
      source: queryString
    };

    try {
      const response = await signup(payload);
      if (response) {
        setIsComplete(true);
        setEmail(response?.user?.email);
        if (result.refetch) {
          result?.refetch();
        }
      }
    } catch (error) {
      setServerErrors({ ...serverErrors, ...error });
      setIsComplete(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validate: validateSignup,
    onSubmit: handleSubmit
  });

  const hasErrors = (field) => {
    return formik.touched[field] && formik.errors[field];
  };

  if (result.data) {
    return (
      <Redirect
        to={{
          pathname: urlWithUTM
        }}
      />
    );
  }

  const plan =
    queryPlan.plan === 'buy_now'
      ? 'Create Your Account'
      : queryPlan.plan === 'free_trial'
      ? 'Start now for free, no card required'
      : 'Get Started with Free Forever';

  if (isLoading) {
    return (
      <Box className={classes.loaderWrapper}>
        <Box style={{ width: '60%', margin: '15em auto', textAlign: 'center' }}>
          <img
            src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638526892/Bizedge_Frontend_Assets/BizLoader_dzmb4i.gif'
            style={{ width: '140px', height: '140px', margin: 'auto' }}
            alt=''
          />
          <Typography variant='subtitle1'>
            Setting up your Business Account
          </Typography>
          <Typography variant='subtitle2'>
            This might take up to a minute, Please do not refresh the page or
            close this tab until the login process has completed
          </Typography>
          <Box width='40%' margin='32px auto 0'>
            <ProgressBar
              bgcolor='#2898A4'
              height='6px'
              completed={completed}
              borderRadius={50}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  const buyNowProps = {
    setIsLoading,
    businessName,
    businessId,
    email,
    formik,
    setFeedback,
    handleClick
  };

  const signUpIntialProps = { plan, serverErrors, formik, hasErrors, width };

  return (
    <>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <title>Sign up to BizEdge</title>
        <meta property='og:title' content='Sign up to BizEdge' />
        <meta
          name='description'
          content='Sign up to the all in one HR, Payroll and Productivity Solution that lets you focus on people, not processes.'
        />
        <meta
          property='og:description'
          content='Sign up to the all in one HR, Payroll and Productivity Solution that lets you focus on people, not processes.'
        />
      </Helmet>

      <AuthFormContainer>
        <Box className='c-sideLeft'>
          <Box className='container'>
            <Box className='info'>
              <img src={BizedgeImage} alt='' />
            </Box>
            <Typography variant='subtitle2' className='intro'>
              All In One for Ease and Effectiveness
            </Typography>
          </Box>
        </Box>
        <Box className={`${classes.root} c-sideRight`}>
          <Box>
            <img
              src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638786088/Bizedge_Frontend_Assets/Bizedge_logo_axcwlg.svg'
              alt='logo'
              style={{ width: '100px', height: '100px', marginLeft: '9%' }}
              className='logo'
            />
          </Box>
          <Box className='c-sideRight-form'>
            {isComplete && queryPlan.plan !== 'buy_now' ? (
              !isBusinessCreated ? (
                <>
                  <BusinessRegistration
                    handleSubmit={handleSubmitBusiness}
                    isLoading={isLoading}
                    {...{ products, setProducts }}
                  />
                </>
              ) : (
                <BuyNow {...buyNowProps} />
              )
            ) : (
              <SignUpInitial {...signUpIntialProps} />
            )}
          </Box>
        </Box>
      </AuthFormContainer>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={feedback.severity}>
          {feedback.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default withWidth()(Signup);
